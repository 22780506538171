import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import Typical from '../../../components/Typical';
import ButtonLink from '../../../components/ButtonLink';
import classNames from 'classnames';
import { HeroCard } from './HeroCard';
import { cards } from './cards';

const HeroBanner: FunctionComponent<{}> = () => {
    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.contents, 'container')}>
                <span className={styles.headline}>
                    <h1>Create Amazing </h1>
                    <Typical
                        steps={[
                            ' Learning',
                            1400,
                            ' Courses',
                            1400,
                            ' Training',
                            1400,
                            ' Events',
                            1400,
                            ' Workshops',
                            1400,
                            ' Webinars',
                            1400,
                            ' Classes',
                            1400,
                        ]}
                        loop="Infinity"
                        wrapper="h1"
                    />
                </span>
                <h3>The all-in-one platform for educators, creators and organisers</h3>
                <div className={styles.cta_container}>
                    <ButtonLink
                        href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/create-educator`}
                        target="_blank"
                        rel="noopener nofollow"
                        preset="primary"
                        className={styles.ctaButton}
                    >
                        Start for Free
                    </ButtonLink>
                </div>
            </div>
            <div className={classNames(styles.carousel, styles.carouselRight)}>
                {cards.map((card, i) => (
                    <HeroCard key={i} {...card} />
                ))}
            </div>
            <div className={classNames(styles.carousel, styles.carouselLeft)}>
                {cards.map((card, i) => (
                    <HeroCard key={i} {...card} />
                ))}
            </div>
        </div>
    );
};

export default HeroBanner;
